import { graphql, Link, useStaticQuery } from "gatsby"
import React, { useEffect, useState } from "react"
import { Col, Container, Nav, Navbar, NavDropdown } from "react-bootstrap"
import { Helmet } from "react-helmet"
import { SlideDown } from "react-slidedown"
import Close from "../assets/images/popup-close.svg"
import Search from "../assets/images/search-icon-black.png"
import ArrowDown from "../assets/svgs/nav-arrow-down.svg"
import ArrowDownMob from "../assets/svgs/nav-arrowdown-mob.svg"
import FeaturedTags from "../components/blog/featured-categories"
import NavLibraryDropdownMob from "../components/navigation/nav_library_dropdown_mob"
import "../styles/component/navigation.scss"
import "../styles/navigation.scss"
import Buttons from "./common/button"
import SVGIcon from "./common/SVGIcon"
import NavigationDropdown from "./NavigationDropdown"
import HelloBar from "./webinar/hello-bar"

function NavigationBlog(props) {
  const [param, setParam] = useState("")
  const [isHelloBarOneVisible, setIsHelloBarOneVisible] = useState(false)
  const [isHelloBarTwoVisible, setIsHelloBarTwoVisible] = useState(false)

  const [isScrolledDown, setScrolledDown] = useState(false)

  const [isScrolledDown2, setScrolledDown2] = useState(false)

  const [isNavOpen, setIsNavOpen] = useState(false)

  const [showDropdown, setShowDropdown] = useState(false)

  const [currentNav, setCurrentNav] = useState(false)

  const [showDropdownMob, setShowDropdownMob] = useState(false)

  const [searchOpen, setSearchOpen] = useState(false)
  const [searchOpenAnim, setSearchOpenAnim] = useState(false)

  const [currentNavMob, setCurrentNavMob] = useState("")

  const [cursorFocus, setCursorFocus] = useState(0)

  useEffect(() => {
    if (cursorFocus > 0) {
      document.getElementById("search").focus()
    }
  }, [cursorFocus])

  const handleDskDropdownMob = (isDropdown, name) => {
    setCurrentNavMob(name !== currentNavMob && name)

    setShowDropdownMob(name)

    setShowDropdownMob(
      isDropdown === "Yes" && name !== currentNavMob ? true : !showDropdownMob
    )
  }

  const handleDskDropdown = name => {
    setCurrentNav(name !== currentNav ? name : false)
  }

  useEffect(() => {
    document.addEventListener("click", evt => {
      const flyoutElementTop = document.getElementById("drp-nav-top")
      const flyoutElementBottom = document.getElementById("drp-nav-bottom")
      let targetElement = evt.target

      do {
        if (
          targetElement === flyoutElementTop ||
          targetElement === flyoutElementBottom
        ) {
          return
        }

        targetElement = targetElement.parentNode
      } while (targetElement)

      setCurrentNav(false)
    })

    window.addEventListener(
      "scroll",
      function() {
        setScrolledDown(window.pageYOffset > 750)
        setScrolledDown2(window.pageYOffset > 150)
      },
      false
    )

    document.onkeydown = function(evt) {
      if (evt.key === "Escape") {
        setCurrentNav(false)
      }
    }
  })

  const handleInputChange = event => {
    setParam(event.target.value)
  }

  const handleInputSubmit = event => {
    if (param && param.length > 0) {
      if (event.key === "Enter") {
        window.location.href = `/blog/search?query=${param}`
      }
    }
  }

  const handleButtonSubmit = () => {
    setCursorFocus(cursorFocus + 1)
    if (param && param.length > 0) {
      window.location.href = `/blog/search?query=${param}`
    }
  }

  const openSearch = () => {
    setSearchOpenAnim(searchOpenAnim => !searchOpenAnim)
    const timer = setTimeout(() => {
      setSearchOpen(searchOpen => !searchOpen)
    }, 200)
    return () => clearTimeout(timer)
  }

  return (
    <>
      <Helmet>
        <body
          className={`${
            isHelloBarOneVisible || isHelloBarTwoVisible
              ? "hello-bar-visible"
              : ""
          }`}
        />
      </Helmet>
      <HelloBar
        isHelloBarVisible={val => setIsHelloBarOneVisible(val)}
        // style={{
        //   background:
        //     !isNavOpen &&
        //     !isScrolledDown &&
        //     (isScrolledDown2 ? props.background + "CC" : props.background),
        // }}
        query="first"
        className={`${!isHelloBarOneVisible ? "closed" : ""}`}
      />
      <HelloBar
        isHelloBarVisible={val => setIsHelloBarTwoVisible(val)}
        // style={{
        //   background:
        //     !isNavOpen &&
        //     !isScrolledDown &&
        //     (isScrolledDown2 ? props.background + "CC" : props.background),
        // }}
        query="second"
        className={`${!isHelloBarTwoVisible ? "closed" : ""}`}
      />
      <div
        className="navigation-blog navbar-expand-lg desk d-dsk"
        style={{
          background:
            !isNavOpen &&
            !isScrolledDown &&
            (isScrolledDown2 ? props.background + "CC" : props.background),
        }}
      >
        <div className={isScrolledDown2 && "frost"}></div>
        <div
          className="top fixed-top"
          style={{
            backdropFilter: isScrolledDown2 && "blur(15px)",
          }}
        >
          <div
            className="Layout-container container-wrapper d-flex align-items-center"
            style={{
              borderBottom: !isNavOpen && isScrolledDown2 && "none",
            }}
          >
            <Container className="d-flex align-items-center justify-content-between">
              <div className="to-left d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center">
                  <Link to="/">
                    <SVGIcon
                      name="spsLogoDarkNew"
                      className="logo-image-wrapper"
                    />
                  </Link>
                  <div
                    className={`${!isScrolledDown2 &&
                      "invisible"} pageTitle a13`}
                  >
                    <Link to="/blog">THE BUGLE</Link>
                  </div>
                </div>
                <div>
                  <Link to="/blog">
                    <img
                      src="https://media.graphcms.com/s4izhO9kSCbVujgt6Q2z"
                      alt="blog logo"
                      className={`blog-logo ${isScrolledDown2 && "invisible"}`}
                      height="10"
                      width="10"
                    />
                  </Link>
                </div>
              </div>

              <div className="to-right d-flex align-items-center">
                <div
                  className={`${!isScrolledDown2 &&
                    "invisible"} to-center d-flex align-items-center`}
                >
                  <div className="blog-navigation-search-input position-relative">
                    <button className="searchBtn">
                      <img
                        onClick={handleButtonSubmit}
                        src={Search}
                        alt="Search"
                      />
                    </button>
                    <input
                      id="search"
                      type="text"
                      className="input-search position-absolute"
                      value={param}
                      onChange={handleInputChange}
                      onKeyDown={handleInputSubmit}
                    />
                  </div>
                  <span className="divider"></span>
                  <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="mr-auto" id="drp-col">
                      {NavDropDownQuery().SuperOps.navigationBlocks.map(
                        (link, i) => {
                          return (
                            link.where === "Mini-resources" && (
                              <>
                                <div className="navigation-new Navigation">
                                  <div key={i}>
                                    {link.isDropdown === "Yes" && (
                                      <NavDropdown
                                        id="drp-nav-top"
                                        className="nav-dropdown"
                                        onClick={() =>
                                          handleDskDropdown(link.name)
                                        }
                                        title={
                                          <Link
                                            className={`nav-link nav-link-drp ${currentNav ===
                                              link.name && "active"}`}
                                          >
                                            <div className="nav-link-pad p-0 p13 text-center">
                                              Resources
                                              <ArrowDown className="arrow" />
                                            </div>
                                          </Link>
                                        }
                                      >
                                        <SlideDown>
                                          <NavigationDropdown data={link} />
                                        </SlideDown>
                                      </NavDropdown>
                                    )}
                                  </div>
                                </div>
                              </>
                            )
                          )
                        }
                      )}
                    </Nav>
                  </Navbar.Collapse>
                </div>
                <Buttons
                  theme="secondary sm demo-btn-left br-47"
                  arrow
                  text="BOOK A DEMO"
                  link="/demo"
                />
                <Buttons
                  theme="primary sm br-47"
                  arrow
                  text="GET STARTED FOR FREE"
                  link="/signup"
                />
              </div>
            </Container>
          </div>
        </div>

        <div className="bottom">
          <Container className="Layout-container d-flex align-items-center justify-content-between">
            <div className="to-left">
              <FeaturedTags />
            </div>

            <div className="to-right d-flex align-items-center">
              {props.searchAvailable ? null : (
                <div className="blog-navigation-search-input position-relative">
                  <button className="searchBtn">
                    <img
                      onClick={handleButtonSubmit}
                      src={Search}
                      alt="Search"
                    />
                  </button>
                  <input
                    id="search"
                    type="text"
                    className="input-search"
                    value={param}
                    onChange={handleInputChange}
                    onKeyDown={handleInputSubmit}
                  />
                </div>
              )}
              <span className="divider"></span>
              <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="mr-auto" id="drp-col">
                  {NavDropDownQuery().SuperOps.navigationBlocks.map(
                    (link, i) => {
                      return (
                        link.where === "Mini-resources" && (
                          <>
                            <div className="navigation-new Navigation">
                              <div key={i}>
                                {link.isDropdown === "Yes" && (
                                  <NavDropdown
                                    id="drp-nav-bottom"
                                    className="nav-dropdown"
                                    onClick={() => handleDskDropdown(link.name)}
                                    title={
                                      <Link
                                        className={`nav-link nav-link-drp ${currentNav ===
                                          link.name && "active"}`}
                                      >
                                        <div className="nav-link-pad p13 p-0 text-center">
                                          Resources
                                          <ArrowDown className="arrow" />
                                        </div>
                                      </Link>
                                    }
                                  >
                                    <SlideDown>
                                      <NavigationDropdown data={link} />
                                    </SlideDown>
                                  </NavDropdown>
                                )}
                              </div>
                            </div>
                          </>
                        )
                      )
                    }
                  )}
                </Nav>
              </Navbar.Collapse>

              {!!props.noWriteForUs ? (
                <div></div>
              ) : (
                <span className="a13">
                  <Link to="/blog/authors#WriteForUs">Write for the Bugle</Link>
                </span>
              )}
            </div>
          </Container>
        </div>
      </div>

      <div
        className={`navigation-blog mob d-mob ${isNavOpen && "open"}`}
        style={{
          background: !isNavOpen && !isScrolledDown && props.background,
          marginBottom: props.showLogo ? "-600px" : "-700px",
        }}
      >
        <Navbar
          expand="lg"
          fixed="top"
          className="brand-nav-light mob blog-mob"
          style={{
            background: !isNavOpen && !isScrolledDown && props.background,
          }}
        >
          <div
            className="wrap"
            style={{
              background: !isNavOpen && !isScrolledDown && props.background,
            }}
          >
            <div className="sp">
              <Container>
                <Navbar.Brand href="/">
                  <SVGIcon
                    name="spsLogoDarkNew"
                    className="logo-image-wrapper"
                  />
                </Navbar.Brand>
                {!props.showLogo && (
                  <span className="pageTitle a13">
                    <Link to="/blog">THE BUGLE</Link>
                  </span>
                )}
                {!isNavOpen && (
                  <div className="blog-navigation-search-mob">
                    {!searchOpenAnim ? (
                      <img
                        onClick={() => {
                          openSearch()
                        }}
                        src={Search}
                        alt="Search"
                      />
                    ) : (
                      <img
                        className="close"
                        onClick={() => openSearch()}
                        src={Close}
                        alt="Search"
                      />
                    )}
                  </div>
                )}
                <Navbar.Toggle
                  aria-controls="basic-navbar-nav"
                  onClick={() => {
                    setSearchOpen(false)
                    setIsNavOpen(!isNavOpen)
                  }}
                />
              </Container>
            </div>
            <Navbar.Collapse id="basic-navbar-nav">
              <Container>
                <Nav className="mr-auto">
                  {props.links &&
                    props.links.map((link, i) => (
                      <div key={i}>
                        {link.isDropdown !== "Yes" && (
                          <Link to={`/${link.slug}`} className="nav-link">
                            {link.name}
                          </Link>
                        )}
                        {link.isDropdown === "Yes" && (
                          <div
                            onClick={() =>
                              handleDskDropdownMob(link.isDropdown, link.name)
                            }
                            onKeyDown={() =>
                              handleDskDropdownMob(link.isDropdown, link.name)
                            }
                            role="button"
                            tabIndex={0}
                            className={`nav-links-mob-norm ${link.name ===
                              "Categories" && "bottomLine"}`}
                          >
                            <Link
                              className={`nav-link blg-nav nav-link-drp ${currentNavMob ===
                                link.name && "active"}`}
                            >
                              <>
                                {link.name}
                                <ArrowDownMob className="arrow arw-mob" />

                                <div id="drp-wrap drp-wrap-mob">
                                  <SlideDown>
                                    {showDropdownMob && (
                                      <span
                                        style={{
                                          display:
                                            currentNavMob !== link.name &&
                                            "none",
                                        }}
                                      >
                                        <>
                                          {currentNavMob === "Categories" && (
                                            <Col
                                              lg={6}
                                              style={{
                                                display: "block",
                                              }}
                                            >
                                              <>
                                                <div className="feat-drp-strip">
                                                  <div className="column-container feat-drp-col-cnt">
                                                    <div className="column-2 column-m-1 column-s-1 feat-drp-col">
                                                      <FeaturedTags mobile />
                                                    </div>
                                                  </div>
                                                </div>
                                              </>
                                            </Col>
                                          )}
                                          {currentNavMob === "Library" && (
                                            <NavLibraryDropdownMob
                                              items={props.libraryLinks}
                                            />
                                          )}
                                        </>
                                      </span>
                                    )}
                                  </SlideDown>
                                </div>
                              </>
                            </Link>
                          </div>
                        )}
                      </div>
                    ))}
                  <span className="nav-link nav-beta-link">
                    <Buttons
                      link="/demo"
                      theme="secondary w-100 demo-btn-left br-47"
                      arrow
                      text="DEMO"
                    />
                    <Buttons
                      link="/signup"
                      theme="primary w-100 mt-3 br-47"
                      arrow
                      text="GET STARTED FOR FREE"
                    />
                  </span>
                </Nav>
              </Container>
            </Navbar.Collapse>
          </div>
          <div
            className="blog-search-mob position-relative"
            style={{
              background: !isNavOpen && !isScrolledDown && props.background,
              padding: searchOpenAnim && "16px 0",
              transform: searchOpenAnim ? "translateY(0)" : "translateY(-40px)",
            }}
          >
            <Container>
              <center>
                {searchOpen ? (
                  <div
                    className="blog-navigation-search-input"
                    style={{
                      background:
                        !isNavOpen && !isScrolledDown && props.background,
                    }}
                  >
                    <input
                      type="text"
                      placeholder="Search"
                      autoFocus
                      value={param}
                      onChange={handleInputChange}
                      onKeyDown={handleInputSubmit}
                    />
                    <img
                      onClick={handleButtonSubmit}
                      src={Search}
                      alt="Search"
                    />
                  </div>
                ) : null}
              </center>
            </Container>
          </div>
        </Navbar>
        <div
          style={{ transition: !isScrolledDown && "margin 0.4s ease" }}
          className={`blog-logo-mob position-relative ${
            searchOpenAnim ? "searchOpen" : null
          }`}
        >
          <Container>
            {props.showLogo && (
              <Link to="/blog">
                <img
                  src="https://media.graphcms.com/s4izhO9kSCbVujgt6Q2z"
                  alt="blog logo"
                  className="blog-logo"
                  height="10"
                  width="10"
                />
              </Link>
            )}
          </Container>
        </div>
      </div>
    </>
  )
}

export default NavigationBlog

export function NavDropDownQuery() {
  const returneddata = useStaticQuery(graphql`
    query navDropDown {
      SuperOps {
        navigationBlocks(where: { where: "Mini-resources" }) {
          name
          isDropdown
          where
          navigationLinks {
            heading
            subText
            link
          }
        }
      }
    }
  `)
  return returneddata
}
