import { Link } from "gatsby"
import React from "react"
import { Col, Row } from "react-bootstrap"
import Buttons from "../common/button"
import SVGIcon from "../common/SVGIcon"

const storyCard = props => {
  return (
    <>
      {props.isBlog === "Yes" && (
        <div>
          {/* <Slide style={{ "--sal-delay": "0.2s" }}> */}
          <Link to={`/blog/${props.slug}`} className="no-text-decoration">
            <div className="card-wrap" style={props.style}>
              {!!props.coverImage && (
                <div
                  className="cover"
                  style={{
                    height: !!props.coverImageHeight && props.coverImageHeight,
                  }}
                >
                  <img
                    src={props.coverImage.url}
                    alt="Cover"
                    height="100"
                    width="100"
                  />
                </div>
              )}

              <div
                className={`content position-relative ${!!props.cardSize &&
                  `content-${props.cardSize}`}`}
                style={{
                  minHeight: props.contentMinHeight,
                  borderTop: props.coverImage && "none",
                  borderRadius: !props.coverImage && "5px",
                }}
              >
                {!!props.tags && (
                  <div className="tag-title">
                    {props.tags.map((tag, tagIndex) => {
                      return (
                        <>
                          <Link
                            to={`/blog/categories/${tag.toLowerCase()}`}
                            className="tdnone"
                          >
                            <span className="p14">
                              {" "}
                              <p> {tag.toLowerCase()} </p>{" "}
                            </span>
                            <p
                              className="line"
                              style={{
                                display:
                                  tagIndex === props.tags.length - 1 && "none",
                              }}
                            >
                              {" "}
                              |{" "}
                            </p>
                          </Link>
                        </>
                      )
                    })}
                  </div>
                )}

                {props.isSingleTag?.length > 0 ? (
                  <div className="tag-title">
                    <Link to={`${props.isSingleTag[1]}`} className="tdnone">
                      <span className="p14">
                        <p> {props.isSingleTag[0]} </p>{" "}
                      </span>
                    </Link>
                  </div>
                ) : null}

                <div className="details">
                  {props.cardSize === "large" ? (
                    <h4
                      className={
                        !!props.headingEllipseLine &&
                        `el-${props.headingEllipseLine}`
                      }
                      style={{ marginTop: !!props.tags ? "24px" : "-4px" }}
                      title={props.title}
                    >
                      {" "}
                      {props.title}{" "}
                    </h4>
                  ) : (
                    // <span className="alt">
                    <h6
                      className={
                        !!props.headingEllipseLine &&
                        `el-${props.headingEllipseLine}`
                      }
                      style={{
                        marginTop: !!props.tags ? "16px" : "-4px",
                        fontSize: props.headingFontSize,
                      }}
                      title={props.title}
                    >
                      {" "}
                      {props.title}{" "}
                    </h6>
                    // </span>
                  )}
                  {!!props.excerpt && !props.noExcerpt && (
                    <span
                      className="p16"
                      // className={props.cardSize === "large" ? "p16" : "p14"}
                    >
                      <p
                        className={
                          !!props.contentEllipseLine &&
                          `el-${props.contentEllipseLine}`
                        }
                        title={props.excerpt}
                      >
                        {" "}
                        {props.excerpt}{" "}
                      </p>
                    </span>
                  )}
                </div>

                <div className="position-absolute credits">
                  <Row>
                    <Col xs={8}>
                      {!props.noCredits ? (
                        <span className="a14">
                          {props.author.team !== "Yes" ? (
                            <Link
                              to={`/blog/author/${props.author.slug}/`}
                              className={
                                !!props.cardSize && `a-${props.cardSize}`
                              }
                            >
                              {" "}
                              {props.author.name}{" "}
                            </Link>
                          ) : (
                            <a
                              className={
                                !!props.cardSize && `a-${props.cardSize}`
                              }
                            >
                              {props.author.name}
                            </a>
                          )}
                        </span>
                      ) : (
                        <div>
                          {props.blockLinkText}{" "}
                          <SVGIcon
                            name="hypertext-arrow-right"
                            className="arrow-right"
                          />
                        </div>
                      )}
                    </Col>
                    <Col xs={4}>
                      <span className="p14">
                        <p
                          className={!!props.cardSize && `p-${props.cardSize}`}
                        >
                          {" "}
                          {props.readTime}{" "}
                        </p>
                      </span>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
          </Link>
          {/* </Slide> */}
        </div>
      )}

      {props.isBlog === "No" && (
        <div>
          {/* <Slide style={{ "--sal-delay": "0.2s" }}> */}
          <Link
            to={`/resources/books/${props.slug}/chapter-1`}
            className="no-text-decoration"
          >
            <div className="card-wrap" style={props.style}>
              {!!props.coverImage && (
                <div
                  className="cover book h100 position-relative"
                  style={{
                    height: !!props.coverImageHeight && props.coverImageHeight,
                  }}
                >
                  <img
                    src={props.coverImage.url}
                    alt="Cover"
                    height="100"
                    width="100"
                  />
                  <Buttons theme="primary mt60" text="READ BOOK" />
                </div>
              )}
            </div>
          </Link>
          {/* </Slide> */}
        </div>
      )}
    </>
  )
}

export default storyCard
